import nanohtml from "nanohtml";
import {
    captchaUtil,
    errorUtil,
    fetchUtil,
    formUtil,
    modalUtil,
    ProfileData,
    profileUtil
} from "../_utils";

class MainProfileManager {

    private submittingVerifyEmail = false;
    private formEmail = '';

    openProfileModal(display: 'register'|'login', message?: HTMLElement) {
        const {user} = profileUtil.getData();
        if (user) {
            return;
        }
        const formTpl = document.getElementById('tpl-profile') as HTMLTemplateElement;
        const form = (<DocumentFragment>formTpl.content.cloneNode(true)).firstElementChild as HTMLFormElement;
        const inputEmail = form.querySelector('input[name=email]') as HTMLInputElement;
        inputEmail.value = this.formEmail;
        form.querySelector('#tpl-profile-register')!.addEventListener('click', () => this.openProfileModal('register'));
        form.querySelector('#tpl-profile-login')!.addEventListener('click', () => this.openProfileModal('login'));
        form.querySelectorAll<HTMLElement>('.tpl-register').forEach(item => item.classList.toggle('hidden', display !== 'register'));
        form.querySelectorAll<HTMLElement>('.tpl-login').forEach(item => item.classList.toggle('hidden', display !== 'login'));
        inputEmail.addEventListener('input', () => this.formEmail = inputEmail.value);
        form.setAttribute('action', `/auth/${display}`);
        if (message) {
            form.append(message);
        }
        const modal = modalUtil.openModal(form, {
            header: display === 'register' ? "Création d'un compte CutPlace" : 'Connexion à CutPlace',
            onClose: () => captchaUtil.remove(form),
        });
        captchaUtil.render(form);
        formUtil.initForm<ProfileData>(form, {
            onSuccess: (data) => {
                profileUtil.setData(data);
                modalUtil.closeModal(modal);
            },
            onError: () => captchaUtil.reset(form),
        });
    }

    async postVerifyEmail() {
        if (this.submittingVerifyEmail) {
            return;
        }
        try {
            this.submittingVerifyEmail = true;
            const {email} = await fetchUtil.fetchJSON('/auth/verify_email/request', {});
            modalUtil.openModal(nanohtml`<div>Un message a été envoyé à l'adresse <strong>${email}</strong>. Veuillez cliquer sur le lien présent dans l'e-mail pour valider votre adresse.</div>`);
        }
        catch (e) {
            if (errorUtil.isProfileError(e)) {
                profileUtil.setData(e.getPayload());
            }
            if (!errorUtil.isHttpError(e, 'CTP_EMAIL_VERIFIED')) {
                modalUtil.openErrorModal(e);
            }
        }
        finally {
            this.submittingVerifyEmail = false;
        }
    }
}

export { MainProfileManager };