import "../../scss/main/main.scss";
import Before from "../../images/before.jpg";
import After from "../../images/after.jpg";
import nanohtml from "nanohtml";
import raw from "nanohtml/raw";
import { CdsButton } from "@cds/core/button";
import { MainProfileManager } from "./main.profile";
import {
    alertUtil,
    errorUtil,
    FetchError,
    fetchUtil,
    modalUtil,
    ProfileData,
    profileUtil,
    uploadUtil
} from "../_utils";
import ImageViewer from "../_viewer";
import { ApiRenovationWithoutFolder } from "../../../types/api";
import { maxRenovationsPerWeek } from "../../../common/vars";

class MainManager {

    readonly layoutElem = document.getElementById('layout')!;
    readonly layoutContainerElem = document.getElementById('layout-container')!;
    readonly formElem = document.getElementById('main-form') as HTMLFormElement;
    readonly formMessageElem = document.getElementById('main-form-message')!;
    readonly formSubmitElem = document.getElementById('main-form-submit') as CdsButton;
    readonly formDownloadElem = document.getElementById('main-form-download') as CdsButton;
    readonly imageInputLabelElem = document.getElementById('image-input-label')!;
    readonly imageInputElem = document.getElementById('image-input') as HTMLInputElement;
    readonly placeSelectElem = document.getElementById('place-select') as HTMLSelectElement;
    readonly styleSelectElem = document.getElementById('style-select') as HTMLSelectElement;

    private file: File|null = null;
    private loading: boolean|number = false;
    private profileManager = new MainProfileManager();
    private viewer = new ImageViewer(Before, After);

    private messageLimitReached = nanohtml`<span>Vos ${maxRenovationsPerWeek} rénovations de la semaine sont expirées. Veuillez revenir la semaine prochaine. Pour toute demande : ${raw('<a target="_blank" href="mai&#108;to&#58;c%&#54;Fnta&#37;6&#51;t%&#52;0%63%&#55;5t%70&#108;%6&#49;ce&#46;co&#109;" cds-text="link">cont&#97;ct&#64;&#99;ut&#112;&#108;ace&#46;&#99;om</a>')}</span>`;

    constructor() {
        uploadUtil.initFileInput(this.imageInputElem, this.layoutElem, ([file], hasHeif) => this.setFile(file || null, hasHeif), (e) => this.resetFile(e.message));
        uploadUtil.initSelects(this.placeSelectElem, this.styleSelectElem);
        this.formDownloadElem.addEventListener('click', () => {
            this.viewer.download();
        });
        this.formElem.addEventListener('submit', (ev) => {
            ev.preventDefault();
            this.submit();
        });

        if (!CSS.supports('aspect-ratio', '3 / 4')) {
            modalUtil.openModal('Votre navigateur ne semble pas supporter les technologies récentes utilisées par ce site. Veuillez le mettre à jour ou changer de navigateur.', {
                header: 'Navigateur obsolète',
            });
        }

        profileUtil.onDataChange(({user, renovation}) => {
            const remainingElem = document.getElementById('main-form-remaining')!;
            const {remaining, access} = renovation;
            let message;
            if (access === 'guest') {
                if (!remaining) {
                    message = nanohtml`<div>${
                        user
                            ? nanohtml`<span cds-text="link" onclick="${() => this.profileManager.postVerifyEmail()}">Vérifiez votre adresse e-mail</span>`
                            : nanohtml`<span cds-text="link" onclick="${() => this.profileManager.openProfileModal('register')}">Créez un compte</span> ou <span cds-text="link" onclick="${() => this.profileManager.openProfileModal('login')}">connectez-vous</span>`
                    } pour accéder à ${maxRenovationsPerWeek} rénovations par semaine.</div>`;
                }
            }
            else {
                message = remaining ? nanohtml`Il reste <strong>${remaining}</strong> rénovation${remaining > 1 ? 's' : ''} cette semaine.` : this.messageLimitReached;
            }
            if (message) {
                remainingElem.innerHTML = '';
                remainingElem.append(alertUtil.createAlert(message));
            }
            else {
                remainingElem.classList.add('hidden');
            }
            this.formSubmitElem.disabled = this.loading !== false;
        });
    }

    submit() {
        if (!this.file) {
            this.displayMessage('Veuillez sélectionner et charger une photo que vous souhaitez rénover', 'warning');
            return;
        }
        const {user, renovation} = profileUtil.getData();
        const {remaining, access} = renovation;
        const fd = new FormData(this.formElem);
        fd.set('file', this.file);
        if (!remaining) {
            if (access === 'guest') {
                if (user) {
                    this.profileManager.postVerifyEmail();
                }
                else {
                    this.profileManager.openProfileModal('register');
                }
            }
            if (access === 'free') {
                modalUtil.openModal(this.messageLimitReached);
            }
            return;
        }
        this.layoutContainerElem.scroll({top: (<HTMLElement>this.viewer.elem.firstElementChild).offsetTop - this.layoutContainerElem.offsetTop - 16, behavior: 'smooth'});
        this.clearMessage();
        this.displayDownload(false);
        this.setLoading(true);
        fetchUtil.fetchJSON<{ started: boolean, renovation: ApiRenovationWithoutFolder, profileData: ProfileData }>('/api/renovation/create', fd)
            .then(({started, renovation, profileData}) => {
                profileUtil.setData(profileData);
                const messageQueue = "La photo est en cours de traitement, elle sera bientôt disponible dans l'onglet \"Mes Rénovations\". Vous serez averti par e-mail quand vos rénovations en attente auront été traitées.";
                if (started) {
                    this.getProgress(renovation.uuid).then((progress) => {
                        this.setLoading(false);
                        if (progress === null) {
                            modalUtil.openModal(messageQueue);
                            return;
                        }
                        if (progress === 'error') {
                            this.displayMessage("Une erreur s'est produite lors du traitement", 'danger');
                            return;
                        }
                        this.viewer.setResult(renovation.uuid);
                        this.displayDownload(true);
                        this.setFile(null, false);
                    });
                }
                else {
                    this.setLoading(false);
                    modalUtil.openModal(messageQueue);
                }
            })
            .catch((err) => {
                this.setLoading(false);
                if (errorUtil.isProfileError(err)) {
                    profileUtil.setData(err.getPayload());
                }
                if (errorUtil.isHttpError(err, 'CTP_RENOVATION_GUEST_UNAVAILABLE')) {
                    this.profileManager.openProfileModal('register', alertUtil.createAlert(err.message, {status: 'warning'}));
                }
                else {
                    this.displayMessage(err instanceof FetchError ? err.message : "Une erreur s'est produite", 'danger');
                }
            })
        ;
    }

    async getProgress(uuid: string): Promise<null|'error'|'done'> {
        const {progress} = await fetchUtil.fetchJSON<{progress: null|number|'error'|'done'}>(`/api/renovation/progress?uuid=${encodeURIComponent(uuid)}`);
        if (typeof progress === 'number') {
            if (progress > 1) {
                this.setLoading(progress);
            }
            return new Promise((resolve) => setTimeout(resolve, 800)).then(() => this.getProgress(uuid));
        }
        return progress;
    }

    setLoading(loading: boolean|number) {
        if (loading === this.loading) {
            return;
        }
        this.loading = loading;
        for (const elem of Array.from(this.formElem.elements)) {
            (<any>elem).disabled = loading !== false;
        }
        this.viewer.setLoading(loading);
        this.imageInputLabelElem.classList.toggle('disabled', loading !== false);
        this.formSubmitElem.disabled = loading !== false;
        this.formSubmitElem.loadingState = loading !== false ? 'loading' : 'default';
    }

    resetFile(error?: string) {
        if (this.file) {
            this.placeSelectElem.value = '';
        }
        this.imageInputElem.value = '';
        this.displayDownload(false);
        this.clearMessage();
        if (error) {
            this.displayMessage(error, 'warning');
        }
    }

    setFile(file: File|null, hasHeif: boolean) {
        if (!file) {
            return;
        }
        this.resetFile();
        this.file = file;
        const src = URL.createObjectURL(file);
        this.viewer.setSource(src);
        if (hasHeif) {
            this.displayMessage("Ce type de fichier, bien que fonctionnel, n'est pas totalement pris en charge, nous recommandons d'utiliser des fichiers JPEG pour une expérience optimale", 'warning');
        }
        this.layoutContainerElem.scroll({top: this.formElem.offsetTop - this.layoutContainerElem.offsetTop + this.formElem.offsetHeight, behavior: 'smooth'});
    }

    displayDownload(display: boolean) {
        this.formDownloadElem.classList.toggle('hidden', !display);
    }

    displayMessage(message: string, status: 'warning'|'danger') {
        this.formMessageElem.innerHTML = '';
        this.formMessageElem.append(alertUtil.createAlert(message, {status}));
        this.formMessageElem.classList.remove('hidden');
    }

    clearMessage() {
        this.formMessageElem.innerHTML = '';
        this.formMessageElem.classList.add('hidden');
    }
}

new MainManager();