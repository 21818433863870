import "../scss/_viewer.scss";
import "image-compare-viewer/src/styles/index.scss";
import ImageCompareViewer from "image-compare-viewer";
import { CdsProgressCircle } from "@cds/core/progress-circle";

class ImageViewer {

    readonly elem = document.getElementById("image-viewer")!;

    private loading: boolean|number = false;
    private imageCompare;

    constructor(private source: string, private result: string, private fromServer = false) {
        this.elem.innerHTML = '';
        const imageBefore = new Image();
        imageBefore.id = 'image-before';
        const imageResult = new Image();
        imageResult.id = 'image-result';
        imageResult.src = result;
        this.elem.append(imageBefore, imageResult);
        this.imageCompare = new ImageCompare();
        this.imageCompare.setImageBefore(source);
        this.imageCompare.setImageAfter(result);
    }

    setLoading(loading: boolean|number) {
        if (loading === this.loading) {
            return;
        }
        this.loading = loading;
        this.imageCompare.setLoading(loading);
    }

    setSource(src: string) {
        this.fromServer = false;
        this.source = src;
        this.imageCompare.setImageBefore(src);
        this.imageCompare.setImageAfter(src, true);
        this.imageCompare.setPosition(10);
    }

    setResult(uuid: string) {
        this.fromServer = true;
        this.result = `/file/renovation/${uuid}/result_1.jpg`;
        this.imageCompare.setImageAfter(this.result, this.fromServer ? false : void 0);
        this.imageCompare.setPosition(90);
    }

    download() {
        //if (navigatorUtil.iOS() || navigatorUtil.firefox()) {
        //    window.open(url, '_blank');
        //    setTimeout(() => {
        //        window.URL.revokeObjectURL(url);
        //    });
        //}
        //else {
        const result = this.result;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = result;
        a.target = '_blank';
        a.download = `cutplace_design.jpg`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            a.remove();
            //window.URL.revokeObjectURL(url);
        });
        //}
    }
}


class ImageCompare {
    readonly elem = document.getElementById("image-viewer")!;
    readonly imageBeforeElem = document.getElementById('image-before') as HTMLImageElement;
    readonly imageResultElem = document.getElementById('image-result') as HTMLImageElement;
    internal: {
        mount: () => void,
        slideWidth: number,
        animationFrameId: number|true|null,
    };
    control: HTMLElement;
    wrapper: HTMLElement;
    fluidWrapper: HTMLElement;
    spinner: CdsProgressCircle;

    //iOS: boolean;

    constructor() {
        this.internal = new ImageCompareViewer(this.elem, {
            controlColor: "#fff",
            controlShadow: false,
            addCircle: true,
            hoverStart: true,
            fluidMode: true,
            startingPoint: 10,
        });
        this.internal.mount();
        this.control = this.elem.querySelector('.icv__control')!;
        this.wrapper = this.elem.querySelector('.icv__wrapper')!;
        this.fluidWrapper = this.elem.querySelector('.icv__fluidwrapper')!;
        //this.iOS = navigatorUtil.iOS();
        //if (!this.iOS) {
            this.elem.querySelectorAll<HTMLElement>('.icv__is--fluid .icv__img').forEach((item) => {
                item.style.display = 'block';
            });
            this.wrapper.style.backgroundImage = 'none';
            this.fluidWrapper.style.backgroundImage = 'none';
        //}
        setTimeout(() => this.setPosition(90, 1000));
        this.wrapper.style.transitionTimingFunction = 'cubic-bezier(0.65, 0, 0.35, 1)';
        this.control.style.transitionTimingFunction = 'cubic-bezier(0.65, 0, 0.35, 1)';
        const spinner = document.createElement('cds-progress-circle') as CdsProgressCircle;
        spinner.classList.add('viewer-spinner', 'hidden');
        spinner.status = 'info';
        spinner.size = 'lg';
        this.spinner = spinner;
        this.elem.querySelector('.icv__circle')!.append(this.spinner);
    }

    setPosition(position: number, duration = 500) {
        this.internal.animationFrameId = requestAnimationFrame(() => {
            this.control.style.transitionDuration = `${duration}ms`;
            this.wrapper.style.transitionDuration = `${duration}ms`;
            this.control.style.left = `calc(${position}% - ${this.internal.slideWidth/2}px)`;
            this.wrapper.style.clipPath = `inset(0 0 0 ${position}%)`
            setTimeout(() => {
                this.control.style.transitionDuration = '';
                this.wrapper.style.transitionDuration = '';
                this.internal.animationFrameId = null;
            });
        });
    }

    setLoading(loading: boolean|number) {
        this.internal.animationFrameId = loading !== false || null;
        this.elem.classList.toggle('image-compare-disabled', loading !== false);
        this.spinner.classList.toggle('hidden', loading === false);
        (<number|undefined>this.spinner.value) = typeof loading === 'boolean' ? void 0 : loading;
    }

    setImageBefore(url: string) {
        const img = new Image();
        img.src = url;
        img.onload = () => {
            this.elem.style.aspectRatio = `${img.width} / ${img.height}`;
            //if (this.iOS) {
            //    this.fluidWrapper.style.backgroundImage = `url("${url}")`;
            //}
            //else {
                this.imageBeforeElem.src = url;
            //}
        }
    }

    setImageAfter(url: string, blur?: boolean) {
        //if (this.iOS) {
        //    this.wrapper.style.backgroundImage = `url("${url}")`;
        //}
        //else {
            this.imageResultElem.src = url;
            if (blur !== void 0) {
                this.imageResultElem.classList.toggle('image-blured', blur);
            }
        //}
    }
}

export default ImageViewer;